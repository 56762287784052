/**
 * notification init to ensure notification DB is setup properly on load
 * This was specially added for AT&T where the DB was containing homepage spaceid
 * @return {void}
 */
export const notificationInit = () => {
    const { wafer } = window;

    if (!wafer.features.localStorage) {
        return;
    }
    // dont read from indexedDB if data is in localstorage
    // this implies, spaceid was updated
    if (window.localStorage.getItem('notifiationSpaceIdUpdated')) {
        return;
    }
    window.localStorage.setItem('notifiationSpaceIdUpdated', '1');

    const indexedDB =
        window.indexedDB || // @ts-ignore
        window.mozIndexedDB || // @ts-ignore
        window.webkitIndexedDB || // @ts-ignore
        window.msIndexedDB;

    if (!indexedDB) {
        return;
    }
    const TABLE = 'subscriptions';
    let db;
    const request = indexedDB.open('yahooNotifications'); // open db connection

    request.onsuccess = (event) => {
        const pageSpaceId = window.rapidInstance?.getRapidAttribute('spaceid');

        // dont do anything if page spaceid does not exist
        if (!pageSpaceId) {
            return;
        }
        db = event.target.result;
        // start with read using readOnly transaction
        let tx;

        try {
            tx = db.transaction(TABLE, 'readonly');
        } catch (e) {
            return;
        }
        const store = tx.objectStore(TABLE);

        if (!store) {
            return;
        }
        const req = store.get('push');

        req.onsuccess = (event) => {
            const res = event.target.result;
            let writeTx;

            // perform write operation now
            try {
                writeTx = db.transaction(TABLE, 'readwrite');
            } catch (e) {
                // do nothing
            }
            const writeStore = writeTx.objectStore(TABLE);

            // update spaceid for user
            writeStore.put({
                ...res,
                spaceId: pageSpaceId,
            });
        };
    };
};
