import { notificationInit as notificationInit } from './utils/notification';
const { wafer } = window;

const waferInit = () => {
    notificationInit();
};

if (wafer) {
    wafer.ready(() => {
        waferInit();
    });
} else {
    document.body.addEventListener('wafer:ready', () => {
        waferInit();
    });
}
